import { twMerge } from 'tailwind-merge';

import { Badge } from '@lamesarv-sdk/components';

interface SpecialOfferBadgeProps {
  className?: string;
}

export const SpecialOfferBadge = ({ className }: SpecialOfferBadgeProps) => (
  <Badge
    dataTestId="special-offer"
    className={twMerge(
      'text-center rounded py-1 px-2.5 text-xs font-semibold bg-primary-04-100 text-primary-04-500',
      className,
    )}
  >
    Special Offer
  </Badge>
);
