import { twMerge } from 'tailwind-merge';

import { Badge } from '@lamesarv-sdk/components';

interface FlashSaleBadgeProps {
  className?: string;
}

export const FlashSaleBadge = ({ className }: FlashSaleBadgeProps) => (
  <Badge
    dataTestId="flash-sale"
    className={twMerge(
      'text-center rounded py-1 px-2.5 text-xs font-semibold bg-primary-02-100 text-primary-02-500',
      className,
    )}
  >
    Flash Sale
  </Badge>
);
