import { useEffect, useState } from 'react';

import { getInventoryPageUrl } from '@lamesarv-sdk/tools';
import { IBasicInventory, InventoryField } from '@lamesarv-sdk/types';

export const useShareInventory = (item: IBasicInventory) => {
  const [canShare, setCanShare] = useState(false);

  const getShareData = () => {
    const shareData: ShareData = {
      title: item[InventoryField.title],
      text: item[InventoryField.description],
      url: getInventoryPageUrl(item),
    };

    return shareData;
  };

  const handleShare = () => {
    navigator.share(getShareData()).catch((error) => {
      if (error.name === 'AbortError') return;

      console.error('Error sharing:', error);
    });
  };

  useEffect(() => {
    setCanShare(!!navigator.share && !!navigator.canShare?.(getShareData()));
  }, []);

  return {
    canShare,
    handleShare,
  };
};
