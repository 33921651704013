import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { getImageUrl } from '@/tools/inventory';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomArrowProps {
  onClick?: () => void;
  className?: string;
  isRight?: boolean;
}

const CustomArrow = ({ className, isRight, onClick }: CustomArrowProps) => (
  <button
    type="button"
    onClick={onClick}
    className={twMerge(
      'absolute top-1/2 -translate-y-1/2 flex items-center justify-center cursor-pointer z-10 outline-none text-white hover:text-blue-50 inventory-item-sub-button',
      isRight ? 'right-1' : 'left-1',
      className,
    )}
    data-testid={`${isRight ? 'right' : 'left'}-arrow`}
  >
    <FontAwesomeIcon
      className="size-8 drop-shadow-[0px_0px_3px_#00000080]"
      icon={isRight ? faChevronRight : faChevronLeft}
    />
  </button>
);

interface InventoryImageSliderProps {
  images: string[];
  width: number;
  height: number;
  className?: string;
  index?: number;
  onIndexChange?: (index: number) => void;
  swipe?: boolean;
  dots?: boolean;
  hideCounter?: boolean;
}

export const InventoryImageSlider = ({
  images,
  height,
  width,
  className,
  index,
  onIndexChange,
  swipe = false,
  dots,
  hideCounter,
}: InventoryImageSliderProps) => {
  const sliderRef = useRef<Slider>(null);
  const isChangingRef = useRef(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (typeof index !== 'number' || !sliderRef.current || isChangingRef.current) return;

    sliderRef.current.slickGoTo(index);
    setCurrentSlide(index);
  }, [index]);

  return (
    <div
      onTouchMove={(e) => {
        e.stopPropagation();
      }}
      className={twMerge('relative', className)}
    >
      <Slider
        infinite={false}
        arrows
        dots={dots}
        dotsClass="bg-[#0F060399] !flex items-center gap-2 absolute bottom-2 left-1/2 -translate-x-1/2 px-1 py-0.5 rounded-full [&_.slick-active_figure]:bg-white [&_.slick-active_figure]:size-2"
        customPaging={() => <figure className="size-1.5 bg-[#98A2A3] rounded-full" />}
        swipe={swipe}
        nextArrow={<CustomArrow isRight />}
        prevArrow={<CustomArrow />}
        beforeChange={(oldIndex, newIndex) => {
          if (oldIndex !== newIndex) {
            isChangingRef.current = true;
          }
          setCurrentSlide(newIndex);
          onIndexChange?.(newIndex);
        }}
        ref={sliderRef}
        afterChange={() => {
          isChangingRef.current = false;
        }}
      >
        {images.map((publicId, idx) => (
          <img
            key={publicId}
            src={getImageUrl(publicId, width, height)}
            alt={`${idx + 1}`}
            className="w-full object-cover"
          />
        ))}
      </Slider>
      {!hideCounter && (
        <div className="absolute bottom-1 right-3 bg-[#0F0603E5] px-2 py-1 rounded-sm flex items-center justify-center">
          <span className="text-xs font-semibold text-white">
            {(index ?? currentSlide) + 1} of {images.length}
          </span>
        </div>
      )}
    </div>
  );
};
