import { twMerge } from 'tailwind-merge';

import { getInventoryType, InventoryType } from '@/types/inventory';
import { Badge } from '@lamesarv-sdk/components';
import { PropsWithClasses } from '@lamesarv-sdk/types';

interface IInventoryTypeBadgeProps extends PropsWithClasses {
  inventoryType: string;
}

export const InventoryTypeBadge = ({ inventoryType, className }: IInventoryTypeBadgeProps) => (
  <Badge
    className={twMerge(
      'text-center rounded py-1 px-2.5 text-xs font-semibold',
      inventoryType === InventoryType.new
        ? 'bg-primary-04-100 text-accent-01-800'
        : 'text-accent-02-500 bg-primary-06-100',
      className,
    )}
    dataTestId={inventoryType}
  >
    {getInventoryType(inventoryType)}
  </Badge>
);
