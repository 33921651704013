'use client';
import '@/styles/inventory-item.css';

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { MouseEvent, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { defaultImageUrl, INVENTORY_IMAGE_HEIGHT, INVENTORY_IMAGE_WIDTH } from '@/constants/inventory';
import { InventoryFormContext } from '@/contexts/InventoryFormContext';
import { LayoutContext } from '@/contexts/LayoutContext';
import { useIsFavorite } from '@/hooks/useIsFavorite';
import { useShareInventory } from '@/hooks/useShareInventory';
import { getImageUrl, getListPriceLabel, isNewUnit } from '@/tools/inventory';
import { isMobile } from '@/tools/views';
import { faHeart as faHeartOutline } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot, faShareFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonType } from '@lamesarv-sdk/components';
import { getFacetCode, getInventoryImage, getInventoryPageUrl, navigate } from '@lamesarv-sdk/tools';
import { IBasicInventory, IInventoryPriceFlag, InventoryField } from '@lamesarv-sdk/types';

import { FlashSaleBadge } from '../Badges/FlashSaleBadge';
import { InventoryTypeBadge } from '../Badges/InventoryTypeBadge';
import { SpecialOfferBadge } from '../Badges/SpecialOfferBadge';
import { InventoryItemImage } from './InventoryItemImage';

export interface IInventoryItemProps {
  item: IBasicInventory;
  customImage?: string;
  sold?: boolean;
  className?: string;
}

const formatPrice = (price: number) => Math.round(price).toLocaleString(undefined, { maximumFractionDigits: 0 });

interface IGenericPricingProps {
  secondaryPrice?: {
    label: string;
    value: number;
  };
  primaryPrice?: {
    label: string;
    value: number;
  };
  displaySave?: boolean;
}

const GenericPricing = ({ secondaryPrice, primaryPrice, displaySave }: IGenericPricingProps) => {
  const bigPrice = primaryPrice || secondaryPrice;
  const smallPrice = primaryPrice && secondaryPrice;

  if (!bigPrice) return null;

  return (
    <div className="flex gap-1.5 flex-col">
      <div className="flex gap-3 text-[1.75rem]">
        {displaySave && smallPrice && bigPrice && bigPrice.value !== smallPrice.value && (
          <span className="text-primary-03-600" data-testid="unit-discount-percentage">
            -{(((smallPrice.value - bigPrice.value) / smallPrice.value) * 100).toFixed(0)}%
          </span>
        )}
        <span
          className={twMerge('font-semibold text-primary-01-950', !displaySave && 'line-through')}
          data-testid="unit-showing-price"
        >
          ${formatPrice(bigPrice.value)}
        </span>
      </div>

      {smallPrice && (
        <span className="text-sm text-neutral-02-600" data-testid="unit-was-price-label">
          {smallPrice.label}{' '}
          <span className="line-through" data-testid="unit-was-price-value">
            ${formatPrice(smallPrice.value)}
          </span>
        </span>
      )}

      {displaySave && smallPrice && bigPrice && bigPrice.value !== smallPrice.value && (
        <span className="font-semibold text-accent-01-500" data-testid="unit-you-save">
          You save ${formatPrice(smallPrice.value - bigPrice.value)}
        </span>
      )}
    </div>
  );
};

const UsedPricing = ({ item }: { item: IBasicInventory }) => (
  <GenericPricing
    displaySave
    primaryPrice={!!item[InventoryField.priceSale] && { label: 'SALE PRICE', value: item[InventoryField.priceSale] }}
    secondaryPrice={
      !!item[InventoryField.priceList] && { label: getListPriceLabel(item), value: item[InventoryField.priceList] }
    }
  />
);

const NewPricing = ({ item }: { item: IBasicInventory }) => {
  const salePrice =
    item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice ||
    item[InventoryField.priceFlag] === IInventoryPriceFlag.codedCost
      ? item[InventoryField.priceSale]
      : item[InventoryField.priceWas];

  const displaySave = item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice;

  return (
    <GenericPricing
      displaySave={displaySave}
      primaryPrice={!!salePrice && { label: 'SALE PRICE', value: salePrice }}
      secondaryPrice={
        !!item[InventoryField.priceList] && { label: getListPriceLabel(item), value: item[InventoryField.priceList] }
      }
    />
  );
};

const InventoryItemCTA = ({ item }: { item: IBasicInventory }) => {
  const { context: layoutContext } = useContext(LayoutContext);
  const router = useRouter();
  const { openMobileInquire } = useContext(InventoryFormContext);

  if (
    !isNewUnit(item) ||
    !item[InventoryField.priceList] ||
    item[InventoryField.priceFlag] === IInventoryPriceFlag.windowPrice
  )
    return null;

  const handleClickCTA = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();

    const inventoryPageUrl = getInventoryPageUrl(item, '');

    if (!isMobile()) {
      navigate(inventoryPageUrl + '#pdpform');
      return;
    }

    router.prefetch(inventoryPageUrl);
    openMobileInquire(item);
  };

  return (
    <button
      type="button"
      className="mt-[1.625rem] cursor-pointer rounded bg-primary-01-950 border border-transparent px-4 h-10 text-center font-semibold text-white cta"
      onClick={handleClickCTA}
      dangerouslySetInnerHTML={{ __html: layoutContext.noPriceButtonContent || '' }}
      data-testid="unit-cta-button"
    />
  );
};

const InventoryItemShare = ({ item }: { item: IBasicInventory }) => {
  const { canShare, handleShare } = useShareInventory(item);

  if (!canShare) return null;

  return (
    <button
      type="button"
      className="group/share bg-white rounded-full inventory-item-sub-button p-2 flex justify-center items-center hover:bg-neutral-01-50"
      data-testid="unit-share-btn"
      onClick={(e) => {
        e.preventDefault();
        handleShare();
      }}
    >
      <FontAwesomeIcon
        icon={faShareFromSquare}
        className="size-4 text-primary-01-950 group-hover/share:text-primary-01-600"
      />
    </button>
  );
};

export const InventoryItem = ({ item, customImage, sold, className }: IInventoryItemProps) => {
  const { isFavorite, toggleFavorite } = useIsFavorite(item, { customImage });

  const inventoryImage = useMemo(() => {
    if (customImage) return customImage;
    if (item[InventoryField.imageCloudinaryIds]?.length) {
      return getImageUrl(item[InventoryField.imageCloudinaryIds][0], INVENTORY_IMAGE_WIDTH, INVENTORY_IMAGE_HEIGHT);
    }
    return getInventoryImage({ item, defaultImageUrl });
  }, [item, defaultImageUrl]);

  const handleSimilarUnits = () => {
    navigate(`/search?model=${getFacetCode(item[InventoryField.model])}`);
  };

  const LinkContainer = sold ? 'div' : Link;

  return (
    <div
      className={twMerge('group xs:mx-2.5 pb-2 h-full font-inter', !sold && 'active-inventory-item', className)}
      data-testid="inventory-unit"
    >
      <LinkContainer
        href={sold ? undefined : getInventoryPageUrl(item)}
        className="flex flex-col h-full m-3 xs:m-4 md:m-0 rounded-md overflow-hidden"
      >
        <div className="relative rounded-t-md overflow-hidden">
          <InventoryItemImage item={item} inventoryImage={inventoryImage} />
          {sold && (
            <div className="absolute inset-0 bg-neutral-400 bg-opacity-70">
              <div className="absolute w-full top-1/2 -translate-y-1/2">
                <div className="py-1 mb-2 bg-neutral-400 bg-opacity-70 sm:text-md text-center text-white font-bold uppercase">
                  Currently not available
                </div>
                <Button
                  type={ButtonType.primary}
                  title="Find similar units"
                  className="py-2 px-4 mx-auto uppercase"
                  onClick={() => handleSimilarUnits()}
                />
              </div>
            </div>
          )}

          {!sold && (
            <div className="absolute top-3 right-4 flex items-center gap-2.5 z-10">
              <InventoryItemShare item={item} />

              <button
                type="button"
                className="group/favorites bg-white rounded-full inventory-item-sub-button p-2 flex justify-center items-center hover:bg-neutral-01-50"
                data-testid="unit-favorite-btn"
                onClick={(e) => {
                  e.preventDefault();

                  toggleFavorite();
                }}
              >
                <FontAwesomeIcon
                  icon={isFavorite ? faHeartSolid : faHeartOutline}
                  className={twMerge(
                    'size-4',
                    isFavorite
                      ? 'text-primary-02-500'
                      : 'text-primary-01-950 group-hover/favorites:text-primary-02-500',
                  )}
                />
              </button>
            </div>
          )}
        </div>
        <div
          className="relative flex flex-col flex-1 justify-between border-b border-x border-neutral-01-300 rounded-b-md p-[1.125rem] pt-3 gap-3.5"
          data-testid="unit-details"
        >
          <div className="flex flex-col gap-3.5">
            <div className="flex gap-2.5">
              <InventoryTypeBadge inventoryType={item[InventoryField.inventoryType]} />
              {!sold && item[InventoryField.special] && <SpecialOfferBadge />}
              {!sold && item[InventoryField.inFlashSale] && <FlashSaleBadge />}
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="font-bold text-xl text-primary-01-950" data-testid="unit-title">
                {item[InventoryField.title]}
              </h3>
              <div className="flex gap-4 items-center text-neutral-02-600 text-sm font-medium">
                <span data-testid="unit-stock-number">Stock #{item[InventoryField.stockNumber]}</span>
                <div className="flex items-center gap-0.5" data-testid="unit-location">
                  <FontAwesomeIcon icon={faLocationDot} className="size-4" />
                  <span>{item[InventoryField.location]}</span>
                </div>
              </div>
            </div>

            {isNewUnit(item) ? <NewPricing item={item} /> : <UsedPricing item={item} />}

            <div className="flex gap-3 items-center text-sm text-neutral-02-600">
              <span data-testid="unit-slideouts">
                Slideouts - <b className="text-primary-01-950">{item[InventoryField.slideOutsCount]}</b>
              </span>
              <figure className="border-l border-neutral-01-300 h-full" />
              <span data-testid="unit-type">
                Type -{' '}
                <b className="text-primary-01-950">
                  {item[InventoryField.body]} - {item[InventoryField.fuelType]}
                </b>
              </span>
            </div>
          </div>

          <InventoryItemCTA item={item} />

          {sold && <div className="absolute inset-0 bg-white bg-opacity-70" />}
        </div>
      </LinkContainer>
    </div>
  );
};
