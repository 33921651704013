import { useContext, useEffect, useMemo, useState } from 'react';

import { defaultImageUrl, INVENTORY_IMAGE_HEIGHT, INVENTORY_IMAGE_WIDTH } from '@/constants/inventory';
import { InventoryContext } from '@/contexts/InventoryContext';
import { getFavoritesChannel } from '@/tools/broadcast';
import { getImageUrl } from '@/tools/inventory';
import { IMessageFavorites, MessageFavoritesType } from '@/types/inventory';
import { getInventoryImage, getMinimalInventory } from '@lamesarv-sdk/tools';
import { IBasicInventory, IInventory, InventoryField } from '@lamesarv-sdk/types';

interface UseIsFavoriteOptions {
  customImage?: string;
}

export const useIsFavorite = (item: IBasicInventory, { customImage }: UseIsFavoriteOptions = {}) => {
  const { context, favoriteSelectInventory, favoriteUnselectInventory } = useContext(InventoryContext);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const inventoryImage = useMemo(() => {
    if (customImage) return customImage;
    if (item[InventoryField.imageCloudinaryIds]?.length) {
      return getImageUrl(item[InventoryField.imageCloudinaryIds][0], INVENTORY_IMAGE_WIDTH, INVENTORY_IMAGE_HEIGHT);
    }
    return getInventoryImage({ item, defaultImageUrl });
  }, [item, defaultImageUrl, customImage]);

  const toggleFavorite = () => {
    const channelFavorites = getFavoritesChannel();

    if (!isFavorite) {
      const minimalItem = getMinimalInventory(item as IInventory, defaultImageUrl, inventoryImage);
      const newFavorites = favoriteSelectInventory(minimalItem, true);
      channelFavorites.postMessage({
        type: MessageFavoritesType.favoriteSelectInventory,
        id: item[InventoryField.id],
        quantity: newFavorites.length,
      } as IMessageFavorites);
      return;
    }

    const newFavorites = favoriteUnselectInventory(item[InventoryField.id], true);
    channelFavorites.postMessage({
      type: MessageFavoritesType.favoriteUnselectInventory,
      id: item[InventoryField.id],
      quantity: newFavorites.length,
    } as IMessageFavorites);
  };

  useEffect(() => {
    const channel = getFavoritesChannel();

    channel.onmessage = (message: IMessageFavorites) => {
      if (message.id === item[InventoryField.id]) {
        setIsFavorite(message.type === MessageFavoritesType.favoriteSelectInventory);
      }
    };

    return () => {
      channel.close();
    };
  }, [item]);

  useEffect(() => {
    setIsFavorite(!!context?.inventoryFavorites?.find((favorite) => favorite.id === item[InventoryField.id]));
  }, [context.inventoryFavorites, item]);

  return {
    toggleFavorite,
    isFavorite,
  };
};
